<template>
    <Head :title="t('Sign in')" />

    <AspectAlert
        v-if="status"
        class="mb-4"
        type="success"
    >
        {{ status }}
    </AspectAlert>

    <form
        class="flex flex-col gap-4"
        @submit.prevent="submit"
    >
        <div class="flex flex-col gap-4">
            <AspectInputGroup>
                <AspectLabel for="email">
                    {{ t('Email') }}
                </AspectLabel>
                <AspectInput
                    v-model="form.email"
                    :autofocus="true"
                    :error="form.errors.email"
                    autocomplete="username"
                    name="email"
                    type="email"
                />
            </AspectInputGroup>

            <AspectInputGroup>
                <AspectLabel for="password">
                    {{ t('Password') }}
                </AspectLabel>
                <AspectInput
                    v-model="form.password"
                    :error="form.errors.password"
                    autocomplete="current-password"
                    name="password"
                    type="password"
                />
            </AspectInputGroup>
        </div>

        <div class="flex items-center justify-between">
            <label class="inline-flex items-center">
                <AspectCheckbox
                    v-model:checked="form.remember"
                    name="remember"
                />
                <span class="ml-2 text-gray-600">{{ t('Remember me') }}</span>
            </label>

            <Link
                v-if="canResetPassword"
                :href="route('password.request')"
                class="rounded text-gray-600 underline hover:text-gray-900 focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            >
                {{ t('Forgot your password?') }}
            </Link>
        </div>

        <div>
            <AspectButton
                :disabled="form.processing"
                class="w-full"
                color="blue"
                type="submit"
            >
                {{ t('Sign in') }}
            </AspectButton>
        </div>
    </form>
</template>

<script lang="ts" setup>
    import { Head, Link } from '@inertiajs/vue3';
    import { route } from 'ziggy';
    import { t } from '@aspect/shared/plugins/i18n.ts';

    import AspectCheckbox from '@aspect/shared/components/aspect-checkbox.vue';
    import AspectInputGroup from '@aspect/shared/components/aspect-input-group.vue';
    import AspectLabel from '@aspect/shared/components/aspect-label.vue';
    import AspectInput from '@aspect/shared/components/aspect-input.vue';
    import AspectButton from '@aspect/shared/components/aspect-button.vue';
    import AspectAlert from '@aspect/shared/components/aspect-alert.vue';

    defineProps<{
        canResetPassword: boolean;
        status?: string;
    }>();

    const form = useForm({
        email: '',
        password: '',
        remember: false,
    });

    const submit = () => {
        form.post(route('login'), {
            onFinish: () => form.reset('password'),
        });
    };
</script>
